import { returnNonNull } from 'src/common/helpers/ArraysHelper';
import { ILabelConfig } from 'src/common/types/LabelsTypes';

import { IWfaLabel, IWfaSessionDetails } from '../../../WfaDashboardServiceTypes';
import { WfaSessionDetailsQuery } from '../WfaSessionDetails.generated';

const mapCustomFieldsToLabels = (
	customFields?: string[][],
	labelsConfig?: ILabelConfig[]
): IWfaLabel[] =>
	customFields
		?.filter(returnNonNull)
		.map(([labelKey, valueKey]) => {
			const labelConfig = labelsConfig.find((config) => config.key === labelKey);
			if (!labelConfig) {
				return null;
			}

			const value = labelConfig.options.find((option) => option.key === valueKey);
			if (!value) {
				return null;
			}

			return {
				name: labelConfig.name,
				value: value.name,
				labelKey: labelConfig.key,
				valueKey: value.key
			};
		})
		.filter(returnNonNull) ?? [];

export const transformSessionDetailsResult = (
	rawResults: WfaSessionDetailsQuery,
	labelsConfig?: ILabelConfig[],
	isLabelsToggleEnabled?: boolean
): IWfaSessionDetails => {
	const session = rawResults?.wfa_session_runs?.[0];
	return {
		sessionId: session?.run_id,
		name: session?.session_name,
		runTime: session?.run_time,
		lastModifiedTime: session?.update_time,
		domain: session?.domain,
		createdBy: session?.user_name,
		detectionTypes: session?.detection_types,
		focusArea: session?.analysis_root_selector,
		labels: isLabelsToggleEnabled
			? mapCustomFieldsToLabels(session?.custom_fields, labelsConfig)
			: []
	};
};
