// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wfa-single-session-header {
  margin-bottom: 30px;
}
.wfa-single-session-header .title-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.wfa-single-session-header .title-container .copy-link-button {
  margin-left: 20px;
}
.wfa-single-session-header .title-container .copy-link-button .copy-text svg {
  width: 32px !important;
  height: 32px !important;
}
.wfa-single-session-header .title-container .edit-session-button {
  margin-left: 8px;
}
.wfa-single-session-header .title-container .main-title .title {
  font-size: 22px;
}`, "",{"version":3,"sources":["webpack://./src/apps/wfa/pages/single-session/header/WfaSingleSessionHeader.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;AACD;AACC;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACC,iBAAA;AACH;AAAG;EACC,sBAAA;EACA,uBAAA;AAEJ;AAEE;EACC,gBAAA;AAAH;AAIG;EACC,eAAA;AAFJ","sourcesContent":[".wfa-single-session-header {\n\tmargin-bottom: 30px;\n\n\t.title-container {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin-bottom: 30px;\n\n\t\t.copy-link-button {\n\t\t\tmargin-left: 20px;\n\t\t\t.copy-text svg {\n\t\t\t\twidth: 32px !important;\n\t\t\t\theight: 32px !important;\n\t\t\t}\n\t\t}\n\n\t\t.edit-session-button {\n\t\t\tmargin-left: 8px;\n\t\t}\n\n\t\t.main-title {\n\t\t\t.title {\n\t\t\t\tfont-size: 22px;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
