// this file is copied from WFA with minor adjustments

import { Logger } from 'src/common/services/Logger';

import { TConfiguration } from '../providers/configurationProvider/ConfigurationProvider';
import { ILabelConfig } from '../types/LabelsTypes';

interface IPartialWFAConfig {
	PLATFORM_LABELS_LIST?: ILabelConfig[];
	[key: string]: unknown;
}

const validateLabelEntry = (
	config: { key?: string; name?: string },
	entryType: string
): boolean => {
	if (!config?.key?.trim().length) {
		Logger.error(`a ${entryType} is missing a key`, config);
		return false;
	}

	if (!config?.name?.trim().length) {
		Logger.error(`${entryType} ${config.key} doesn't have a configured name`);
		return false;
	}

	if (config?.name?.trim().length > 30) {
		Logger.warn(`${entryType} ${config.key} name is longer than 30 characters`);
		config.name = config.name.trim().slice(0, 30);
	}

	return true;
};

export const validateAndCleanLabelsConfig = (config: IPartialWFAConfig): IPartialWFAConfig => {
	if (!config) {
		return config;
	}

	const labelConfigs =
		config?.PLATFORM_LABELS_LIST?.filter((labelConfig) => {
			if (!validateLabelEntry(labelConfig, 'label')) {
				return false;
			}

			const validOptions = labelConfig.options?.filter((option) =>
				validateLabelEntry(option, 'label option')
			);

			const hasValidOptions = validOptions.some((option) => !option.isDeleted);
			if (!hasValidOptions) {
				Logger.error(`label ${labelConfig.key} is missing valid options`);
			}

			return true;
		}) ?? [];

	return { ...config, PLATFORM_LABELS_LIST: labelConfigs };
};

export const validateAndCleanCentralConfig = (config: TConfiguration): TConfiguration => {
	if (!config.WFA) {
		return config;
	}
	const labelsValidatedWFAConfig = validateAndCleanLabelsConfig(config.WFA as IPartialWFAConfig);

	return { ...config, WFA: labelsValidatedWFAConfig };
};
