/* eslint-disable no-console */
/* eslint-disable camelcase */
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import uniqBy from 'lodash-es/uniqBy';
import { returnNonNull } from 'src/common/helpers/ArraysHelper';
import { ILabelConfig } from 'src/common/types/LabelsTypes';

import { IWfaLabelOptions } from '../../../WfaDashboardServiceTypes';
import { WfaLabelOptionsQuery } from '../WfaLabelOptions.generated';

export const createFullLabelOptionsFromOptionKeysAndLabelConfig = (
	optionKeys: string[],
	labelConfig: ILabelConfig
): IWfaLabelOptions | null => {
	const labelOptions = optionKeys
		.map((labelOption) => labelConfig.options.find((option) => option.key === labelOption) ?? null)
		.filter(returnNonNull)
		.map((option) => ({ value: option.key, label: option.name }))
		.sort((label1, label2) => label1.label.localeCompare(label2.label));

	if (labelOptions.length) {
		return {
			labelKey: labelConfig.key,
			labelName: labelConfig.name,
			options: labelOptions
		};
	}

	return null;
};

export const extractUniqCustomLabelsGroupedByLabelKeys = (
	rawResults?: WfaLabelOptionsQuery
): { [key: string]: string[] } => {
	const allCustomFields =
		rawResults?.wfa_session_runs
			?.filter(returnNonNull)
			?.flatMap(({ custom_fields }) => custom_fields) ?? [];

	const uniqueCustomFields = uniqBy(allCustomFields, (item) => item.join(':')).filter(
		(label) => label.length
	);

	const a = mapValues(groupBy(uniqueCustomFields, 0), (label) =>
		label.map(([, labelValue]) => labelValue)?.filter(returnNonNull)
	);

	return a;
};

export const transformWfaLabelOptionsResults = (
	rawResults: WfaLabelOptionsQuery,
	labelsConfig: ILabelConfig[]
): IWfaLabelOptions[] => {
	const labelOptionKeysGroupedByLabelKeys = extractUniqCustomLabelsGroupedByLabelKeys(rawResults);
	const result: IWfaLabelOptions[] = [];

	Object.keys(labelOptionKeysGroupedByLabelKeys).forEach((labelKey) => {
		const labelConfig = labelsConfig.find((label) => label.key === labelKey);
		if (!labelConfig) {
			return;
		}
		const labelOptions = createFullLabelOptionsFromOptionKeysAndLabelConfig(
			labelOptionKeysGroupedByLabelKeys[labelKey],
			labelConfig
		);

		if (labelOptions) {
			result.push(labelOptions);
		}
	});

	return result;
};
