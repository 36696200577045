import { trim } from 'lodash-es';

import { IWfaLabel, IWfaSessionDetails } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import { ITagGroup } from 'src/common/components/tags-block/TagsBlock';
import { formatShortMonthDatetime } from 'src/common/helpers/DateFormatHelper';

import { formatDetectionTypesLabel } from './DetectionTypeHelper';

export const getTagsFromSessionDetails = (
	sessionDetails: IWfaSessionDetails,
	overridenLabels?: IWfaLabel[]
): ITagGroup[] => [
	{
		tags: [
			{
				name: 'Date',
				value: formatShortMonthDatetime(sessionDetails.runTime)
			},
			{
				name: 'Last modified',
				value: formatShortMonthDatetime(sessionDetails.lastModifiedTime)
			},
			{
				name: 'Created by',
				value: trim(sessionDetails.createdBy) ?? 'N/A'
			},
			{
				name: 'Domain',
				value: sessionDetails.domain
			},
			...(overridenLabels ?? sessionDetails?.labels ?? [])
		]
	},
	{
		title: 'Session Settings',
		tags: [
			{
				name: 'Session configuration',
				value: formatDetectionTypesLabel(sessionDetails.detectionTypes)
			},
			{
				name: 'Focus area',
				value: sessionDetails.focusArea
			}
		]
	}
];
