import React from 'react';

import { EvLinkTypes, EvTableColumn } from '@evinced-private/ui-common';

import { NAME_NOT_PROVIDED } from 'src/apps/wfa/helpers/WfaDashboardConsts';
import { IWfaSessionRow } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import { DashboardTableLabel } from 'src/common/components/DashboardTableLabel/DashboardTableLabel';
import { EvLinkLocal } from 'src/common/components/ev-link-local/EvLinkLocal';
import { renderNumberCellFormatter } from 'src/common/components/ev-table/cell-formatters/numberCellFormatter';
import { returnNonNull } from 'src/common/helpers/ArraysHelper';
import { formatShortMonthDatetime } from 'src/common/helpers/DateFormatHelper';
import { RoutesHelper } from 'src/common/helpers/RoutesHelper';
import { SessionViews } from 'src/common/types/WfaSessionViews';

export const getWfaSessionsColumns = (isLabelsToggleEnabled?: boolean): EvTableColumn[] => {
	return [
		{
			dataField: 'sessionName',
			text: 'Session',
			style: { width: '200px' },
			headerStyle: { width: '200px' },
			sort: true,
			formatter: (displayName: string, session: IWfaSessionRow) => {
				// todo change to sessions paths
				return (
					<EvLinkLocal
						className="wfa-session-name"
						id={session.sessionId}
						url={RoutesHelper.getWfaSessionPath(session.sessionId, SessionViews.OVERVIEW)}
						ariaLabel={`${displayName} Session`}
						linkText={session.sessionName || NAME_NOT_PROVIDED}
						type={EvLinkTypes.DARK}
					/>
				);
			}
		},
		{
			dataField: 'totalIssues',
			text: 'Total Issues',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'criticalIssues',
			text: 'Critical Issues',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'runTime',
			text: 'Last Modified',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: (cell: string): string => formatShortMonthDatetime(cell)
		},
		{
			dataField: 'createdBy',
			text: 'Created by',
			style: { width: '135px' },
			headerStyle: { width: '135px' },
			sort: true
		},
		isLabelsToggleEnabled
			? {
					dataField: 'labels',
					text: 'Labels',
					style: { width: '182px' },
					headerStyle: { width: '182px' },
					formatter: (labels: string[]) => <DashboardTableLabel labels={labels} />
				}
			: null
	].filter(returnNonNull);
};
