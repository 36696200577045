import { replayIntegration, setUser as setSentryUser } from '@sentry/browser';
import { browserTracingIntegration, init as initSentry, setTag } from '@sentry/react';
import * as Sentry from '@sentry/react';

import { isCI, isDev } from 'src/common/helpers/EnvironmentHelper';
import { ITenant } from 'src/common/interfaces/Tenant';
import { IUser } from 'src/common/interfaces/User';
import { Logger } from 'src/common/services/Logger';

type Message = { msg: string; metadata?: Record<string, string> };

const captureSentryError = (message: Message): void => {
	try {
		Sentry.withScope((scope) => {
			scope.setExtras(message.metadata);
			Sentry?.captureException(new Error(message.msg));
		});
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('Error capturing Sentry exception', e);
	}
};

export const InitSentry = (): void => {
	try {
		// Don't send events in development
		if (isDev() || isCI()) {
			return;
		}

		initSentry({
			dsn: process.env.SENTRY_DSN,
			integrations: [
				browserTracingIntegration(),
				replayIntegration({
					maskAllText: false,
					blockAllMedia: false
				})
			],
			// Performance Monitoring
			tracesSampleRate: 1.0, //  Capture 100% of the transactions
			// Session Replay
			// eslint-disable-next-line max-len
			replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			// eslint-disable-next-line max-len
			replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			// eslint-disable-next-line no-undef
			release: `${UI_VERSION}`,
			ignoreErrors: ['Non-Error promise rejection captured'] // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
		});

		setTag('project-id', process.env.SENTRY_PROJECT_ID);
		Logger.setLogMethod(captureSentryError);
	} catch (error) {
		Logger.error('Error initializing Sentry', error);
	}
};

export const initSentryUser = (user: IUser, tenant: ITenant): void => {
	try {
		const userInfo = {
			email: user.email ?? '',
			role: user.role.name ?? '',
			roleType: user.role.type ?? ''
		};

		const tenantInfo = {
			accountPlan: tenant.accountPlan ?? '',
			companyName: tenant.companyName ?? '',
			id: tenant.id ?? '',
			name: tenant.name ?? ''
		};

		setSentryUser({
			id: user.id,
			username: `${user.name ?? ''} ${user.surname ?? ''}`,
			...tenantInfo,
			...userInfo
		});
	} catch (error) {
		Logger.error('Error initializing Sentry user', error);
	}
};
