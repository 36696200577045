import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getWfaSessionDetails } from 'src/apps/wfa/services/WfaDashboardService';
import { IWfaSessionDetails } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import { WFA_SESSION_DETAILS } from 'src/common/providers/reactQueryProvider/QueryKeys';
import { ILabelConfig } from 'src/common/types/LabelsTypes';

export const useWfaSessionDetailsQuery = (
	sessionId: string,
	labelsConfig?: ILabelConfig[],
	isLabelsToggleEnabled?: boolean
): UseQueryResult<IWfaSessionDetails> => {
	const sessionDetailsQuery = useQuery([WFA_SESSION_DETAILS, sessionId], () =>
		getWfaSessionDetails(sessionId, labelsConfig, isLabelsToggleEnabled)
	);

	return sessionDetailsQuery;
};
