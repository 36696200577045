import { apiCall } from 'src/common/services/api/ServerApi';
import { Logger } from 'src/common/services/Logger';

import { IWfaLabel } from '../WfaDashboardServiceTypes';

import {
	buildBasePayloadForUpdateRequest,
	buildModificationsArrayForCustomFields,
	buildUpdateUrl
} from './WfaSessionUpdateServiceHelpers';

export const updateSession = async (
	sessionId: string,
	name?: string,
	oldLabels?: IWfaLabel[],
	newLabels?: IWfaLabel[]
): Promise<void> => {
	try {
		const payload = buildBasePayloadForUpdateRequest(sessionId, 'all');

		if (name) {
			payload.changes.push({
				fieldName: 'session_name',
				value: name,
				operation: 'set'
			});
		}

		if (typeof newLabels !== 'undefined') {
			const customFieldsChanges = buildModificationsArrayForCustomFields(oldLabels, newLabels);
			payload.changes.push(...customFieldsChanges);
		}

		if (!payload.changes.length) {
			return;
		}

		const response = await apiCall('POST', buildUpdateUrl(), {
			data: payload,
			isAbsoluteUrl: true
		});
		Logger.info('Session updated successfully', response.status, response.data);
	} catch (e) {
		Logger.error('Error updating session:', sessionId, e);
	}
};
