import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getWfaLabelFilterOptions } from 'src/apps/wfa/services/WfaDashboardService';
import { WFA_LABEL_OPTIONS } from 'src/common/providers/reactQueryProvider/QueryKeys';
import { ILabelConfig } from 'src/common/types/LabelsTypes';

import { IWfaLabelOptions } from '../services/WfaDashboardServiceTypes';

export const useWfaLabelFilterOptionsQuery = (
	labelsConfig: ILabelConfig[],
	isEnabled?: boolean
): UseQueryResult<IWfaLabelOptions[]> => {
	const wfaLabelOptionsQuery = useQuery(
		[WFA_LABEL_OPTIONS],
		() => getWfaLabelFilterOptions(labelsConfig),
		{ enabled: isEnabled }
	);

	return wfaLabelOptionsQuery;
};
