import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import {
	BUTTON_TYPES,
	DropdownOptionType,
	EvAppsDropdownMenu,
	EvButton,
	EvDropdownMenu,
	EvIcon,
	EvTitle,
	IDropdownMenuOption,
	UserIcon
} from '@evinced-private/ui-common';

import { RoutesHelper } from 'src/common/helpers/RoutesHelper';
import { ProductType } from 'src/common/interfaces/Tenant';
import { useConfiguration } from 'src/common/providers/configurationProvider/ConfigurationProvider';
import { useUserTenant } from 'src/common/providers/userTenantProvider/UserTenantProvider';
import { isEvincedUser, logout } from 'src/common/services/AuthenticationService';
import { getProductDashboardAccessPermission } from 'src/common/services/CommonDashboardService';

import { useUniversalLogin } from '../../hooks/useUniversalLogin';
import {
	MFA_DASHBOARD_HEADER_TITLE,
	MSDK_DASHBOARD_HEADER_TITLE,
	WEB_SDK_DASHBOARD_HEADER_TITLE,
	WFA_DASHBOARD_HEADER_TITLE
} from '../../pages/dashboards-container/dashboard_consts';
import { ContactUsButton } from '../contact-us/ContactUsButton';
import { DevTogglesPopup } from '../developer-toggles/DevTogglesPopup';

import { getEvincedAppsDropdownMenuOptions } from './ev-header-helper';

import './EvHeader.scss';

type UserMenuOption = {
	id: string;
	title: string;
	type: DropdownOptionType;
	hasBottomBorder: boolean;
	disabled: boolean;
	buttonProps?: {
		title: string;
		type: BUTTON_TYPES;
		children: string | JSX.Element;
		onClick: () => void;
	};
	renderOption?: (accesibilityProps) => JSX.Element;
	url?: string;
	openInNewTab?: boolean;
};
interface IEvHeaderProps {
	isAuthenticatedPage: boolean;
	logoIcon: string;
	onLogoClickUrl: string;
	switchButtonProps?: {
		title: string;
		onClick: () => void;
	};
	className?: string;
}

export const EvHeader: FC<IEvHeaderProps> = ({
	isAuthenticatedPage,
	logoIcon,
	onLogoClickUrl,
	switchButtonProps,
	className,
	children
}) => {
	const [isDevTogglesPopupOpen, setDevTogglesPopupOpen] = useState<boolean>(false);
	const [shouldShowInviteOption, setShouldShowInviteOption] = useState<boolean>(false);
	const [userEmail, setUserEmail] = useState('');
	const location = useLocation();
	const { isAuthenticated } = useUniversalLogin();
	const { tenant, user, hasAdminPermissions, getProductIdByType } = useUserTenant();
	const { getToggle } = useConfiguration();

	const onLogoClick = (e): void => {
		if (e.shiftKey && e.altKey) {
			e.preventDefault();
			setDevTogglesPopupOpen(true);
		}
	};

	// TODO: should on click be different for MFA and SDK dashboards?
	const renderLogo = (): JSX.Element => {
		return (
			<Link
				className="ev-header-logo"
				to={onLogoClickUrl}
				aria-label="Platform Home Page"
				onClick={onLogoClick}
			>
				<EvIcon icon={logoIcon} />
			</Link>
		);
	};

	const getUserMenuOptions = (): UserMenuOption[] => {
		const options = [];
		if (isAuthenticated()) {
			options.push(
				{
					id: 'email',
					title: userEmail,
					buttonProps: {
						title: userEmail,
						type: BUTTON_TYPES.ICON,
						children: userEmail
					},
					hasBottomBorder: true,
					disabled: true,
					type: DropdownOptionType.BUTTON
				},
				{
					id: 'support',
					renderOption: (accessibilityProps): JSX.Element => {
						return (
							<ContactUsButton type={BUTTON_TYPES.ICON} accessibilityProps={accessibilityProps} />
						);
					},
					hasBottomBorder: true,
					type: DropdownOptionType.CUSTOM
				}
			);
		}
		// render user invitation option only if the user has a tenant
		if (shouldShowInviteOption && hasAdminPermissions()) {
			options.splice(1, 0, {
				id: 'user-invitation',
				url: RoutesHelper.getUsersInvitationsHubPath(),
				title: 'Manage Users',
				type: DropdownOptionType.LINK,
				hasBottomBorder: true,
				openInNewTab: true
			});
		}
		options.push({
			id: 'logout',
			buttonProps: {
				title: 'Logout',
				type: BUTTON_TYPES.ICON,
				onClick: () => {
					logout();
				},
				children: 'Logout'
			},
			type: DropdownOptionType.BUTTON
		});

		return options;
	};

	const updateShouldShowInviteOption = useCallback((): void => {
		const isUserAuthenticated = isAuthenticated();
		if (!isAuthenticatedPage || !isUserAuthenticated) {
			setShouldShowInviteOption(false);
			return;
		}
		setShouldShowInviteOption(!!tenant);
	}, [isAuthenticated, isAuthenticatedPage, tenant]);

	useEffect(() => {
		setUserEmail(user?.email || '');
	}, [user]);

	useEffect(() => {
		if (shouldShowInviteOption) {
			return;
		}
		updateShouldShowInviteOption();
	}, [location, shouldShowInviteOption, updateShouldShowInviteOption]);

	const getEvincedAppsMenuOptions = (): IDropdownMenuOption[] => {
		if (isAuthenticated) {
			return getEvincedAppsDropdownMenuOptions({
				hasMfaLicense: getProductDashboardAccessPermission(
					tenant,
					getToggle,
					ProductType.MOBILE_FLOW_ANALYZER
				),
				hasMsdkLicense: getProductDashboardAccessPermission(
					tenant,
					getToggle,
					ProductType.MOBILE_SDK
				),
				hasWebSdkLicense: getProductDashboardAccessPermission(
					tenant,
					getToggle,
					ProductType.WEB_SDK
				),
				hasSiteScannerLicense: !!getProductIdByType(ProductType.SCANNER),
				hasWfaLicense: !!getProductIdByType(ProductType.WEB_FLOW_ANALYZER)
			});
		}

		return [];
	};

	const renderUserMenu = (): JSX.Element => {
		return (
			<div className="ev-user-menu">
				<EvDropdownMenu
					id="user-menu"
					triggerButtonProps={{
						type: BUTTON_TYPES.ICON,
						title: 'User Menu',
						children: <EvIcon icon={UserIcon} />
					}}
					options={getUserMenuOptions()}
				/>
			</div>
		);
	};

	const renderNavigationMenu = (): JSX.Element => {
		return <div className="ev-header-menu">{children}</div>;
	};

	const getDashboardHeaderTitle = (): string => {
		if (location.pathname.includes(RoutesHelper.getMobileSdkDashboardPath())) {
			return MSDK_DASHBOARD_HEADER_TITLE;
		}
		if (location.pathname.includes(RoutesHelper.getMfaDashboardPath())) {
			return MFA_DASHBOARD_HEADER_TITLE;
		}
		if (location.pathname.includes(RoutesHelper.getWebSdkDashboardPath())) {
			return WEB_SDK_DASHBOARD_HEADER_TITLE;
		}
		if (location.pathname.includes(RoutesHelper.getWfaDashboardPath())) {
			return WFA_DASHBOARD_HEADER_TITLE;
		}
		return '';
	};

	const renderProductsDropdownMenu = (): JSX.Element => {
		if (isEvincedUser() && tenant?.products?.length > 1) {
			return <EvAppsDropdownMenu apps={getEvincedAppsMenuOptions()} />;
		}
		return null;
	};

	const isHTMLReportPage: boolean =
		location.pathname === RoutesHelper.getMfaSessionReportPathname() ||
		location.pathname === RoutesHelper.getMobileSdkTestReportPathname();

	return (
		<header className={classNames('ev-header', { [className]: !!className })}>
			<div className={classNames('header-content', { 'html-report-header': isHTMLReportPage })}>
				{renderLogo()}
				<div className="dashboard-header-content">
					{renderProductsDropdownMenu()}
					<EvTitle className="header-title" titleText={getDashboardHeaderTitle()} />
					{renderNavigationMenu()}
				</div>

				<div className="header-content-shift-right">
					{switchButtonProps && (
						<EvButton
							className="switch-to-button"
							type={BUTTON_TYPES.ACTION}
							title={switchButtonProps.title}
							onClick={switchButtonProps.onClick}
						>
							{switchButtonProps.title}
						</EvButton>
					)}
					{isAuthenticatedPage && renderUserMenu()}
				</div>

				<DevTogglesPopup
					isOpen={isDevTogglesPopupOpen}
					closePopup={() => {
						setDevTogglesPopupOpen(false);
					}}
				/>
			</div>
		</header>
	);
};
