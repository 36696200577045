import React from 'react';

import { SessionInfoPopup } from 'src/apps/wfa/components/session-info-popup/SessionInfoPopup';
import { IWfaLabel, IWfaSessionDetails } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import { IReportInfo } from 'src/common/components/report-info/ReportInfo';
import { formatShortMonthDatetime } from 'src/common/helpers/DateFormatHelper';

export const getWfaInfoItems = (labels?: IWfaLabel[]): IReportInfo[] => [
	{ key: 'runTime', label: 'Session date', textFormatter: formatShortMonthDatetime },
	{
		key: 'createdBy',
		label: 'Created by'
	},
	...(labels?.slice(0, 3).map((label) => ({
		key: `${label.name}/${label.value}`,
		label: label.name,
		customValueRenderer: () => label.value
	})) ?? []),
	{
		key: 'sessionInformation',
		label: 'Session information',
		customValueRenderer: (session: IWfaSessionDetails) => (
			<SessionInfoPopup
				name={session.name}
				sessionDetails={session}
				title="Session information"
				overridenLabels={labels}
			/>
		)
	}
];
