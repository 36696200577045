import React, { FC, useMemo } from 'react';

import {
	EvFilters,
	EvSection,
	EvSpinner,
	EvTitle,
	IFilterValues,
	TABLE_FILTERS_SR_SUMMARY
} from '@evinced-private/ui-common';

import {
	WFA_DASHBOARD_PAGE_FILTERS_SECTION_ID,
	WFA_DASHBOARD_PAGE_MAIN_TITLE_ID
} from 'data-automation-ids/WfaDashboardAutomationIds';
import omit from 'lodash-es/omit';
import { useWfaLabelFilterOptionsQuery } from 'src/apps/wfa/hooks/useWfaLabelFilterOptionsQuery';
import { useWfaUserOptionsQuery } from 'src/apps/wfa/hooks/useWfaUserOptionsQuery';
import { IFilterOptions } from 'src/common/helpers/DataFilterHelper';
import { flattenObject } from 'src/common/helpers/ObjectsHelper';
import { WFA_SESSIONS_TABLE_ID } from 'src/common/pages/dashboards-container/dashboard_consts';
import { ILabelConfig } from 'src/common/types/LabelsTypes';

import {
	getWfaSessionsFilterDefinitions,
	SESSIONS_PERIOD_OPTIONS
} from './WfaSessionsFilterDefinitions';

import './WfaSessionsHeader.scss';

const WFA_PAGE_TITLE = 'Web Flow Analyzer session reports';

interface WfaSessionsPageHeaderProps {
	filters: IFilterOptions;
	onFilterChange: (tableId: string, filters: IFilterOptions) => void;
	labelsConfig: ILabelConfig[];
	isLabelsToggleEnabled?: boolean;
}

const EMPTY_CREATED_BY_OPTION = { value: ' ', label: 'Unknown' };

export const WfaSessionsPageHeader: FC<WfaSessionsPageHeaderProps> = ({
	filters,
	onFilterChange,
	labelsConfig,
	isLabelsToggleEnabled
}) => {
	const userOptionsQuery = useWfaUserOptionsQuery();
	const labelOptionsQuery = useWfaLabelFilterOptionsQuery(labelsConfig, isLabelsToggleEnabled);

	const filterDefinitions = useMemo(
		() => getWfaSessionsFilterDefinitions(labelOptionsQuery.data),
		[labelOptionsQuery.data]
	);

	const initialValues = useMemo(() => {
		const selectFilters = omit(filters, 'searchValue');
		labelOptionsQuery.data?.forEach((labelOption) => {
			if (!selectFilters[labelOption.labelKey]) {
				selectFilters[labelOption.labelKey] = [];
			}
		});
		return {
			text: { searchValue: filters?.searchValue },
			select: selectFilters
		};
	}, [filters, labelOptionsQuery.data]);

	const filterOptions = useMemo(() => {
		const staticFilterOptions = {
			sessionsPeriod: SESSIONS_PERIOD_OPTIONS,
			createdBy:
				userOptionsQuery.data?.map((item) => {
					if (typeof item.value === 'string' && item.value === ' ') {
						return EMPTY_CREATED_BY_OPTION;
					}
					return item;
				}) ?? []
		};

		const dyncamicFilterOptions = {};

		labelOptionsQuery.data?.forEach((label) => {
			dyncamicFilterOptions[label.labelKey] = label.options;
		});

		return { ...staticFilterOptions, ...dyncamicFilterOptions };
	}, [labelOptionsQuery.data, userOptionsQuery.data]);

	const handleApply = (appliedFilters: IFilterValues): void => {
		const flatFilters = flattenObject<IFilterOptions>(appliedFilters);
		onFilterChange(WFA_SESSIONS_TABLE_ID, flatFilters);
	};

	const isLoading =
		userOptionsQuery.isLoading || (isLabelsToggleEnabled && labelOptionsQuery.isLoading);

	return (
		<div className="wfa-sessions-header">
			<EvTitle
				titleText={WFA_PAGE_TITLE}
				dataAutomationId={WFA_DASHBOARD_PAGE_MAIN_TITLE_ID}
				headingLevel={1}
				className="main-title page-heading"
			/>
			<EvSection
				ariaLabel="Web Flow Analyzer filters"
				ariaDescribedby={TABLE_FILTERS_SR_SUMMARY}
				ariaLive="polite"
				skipLinkId="filters-section"
				className="dashboard-section"
				dataAutomationId={WFA_DASHBOARD_PAGE_FILTERS_SECTION_ID}
			>
				{!isLoading ? (
					<EvFilters
						className="data-filters"
						onApply={handleApply}
						onReset={handleApply}
						initialValues={initialValues}
						filterOptions={filterOptions}
						filterDefinitions={filterDefinitions}
						isFilterOptionsLoaded={!isLoading}
						isInitialValuesLoaded={!isLoading}
					/>
				) : (
					<EvSpinner />
				)}
			</EvSection>
		</div>
	);
};
