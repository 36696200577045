import { returnNonNull } from 'src/common/helpers/ArraysHelper';
import { ILabelConfig } from 'src/common/types/LabelsTypes';

import { IWfaSessionRow, TWfaSessionsResult } from '../../../WfaDashboardServiceTypes';
import { WfaSessionsQuery } from '../WfaSessions.generated';

const mapCustomFieldsToValueStrings = (
	customFields: string[][],
	labelsConfig?: ILabelConfig[]
): string[] =>
	customFields
		?.map(([labelKey, valueKey]) => {
			const label = labelsConfig?.find((labelConfig) => labelConfig.key === labelKey);
			if (!label) {
				return null;
			}
			const value = label.options?.find((option) => option.key === valueKey)?.name;

			return value || null;
		})
		?.filter(returnNonNull) ?? [];

export const transformSessionsResults = (
	rawResults: WfaSessionsQuery,
	labelsConfig?: ILabelConfig[]
): TWfaSessionsResult => {
	const rows: IWfaSessionRow[] =
		rawResults.wfa_session_runs?.filter(returnNonNull).map((session) => ({
			sessionId: session.run_id,
			totalIssues: session.total_issues,
			criticalIssues: session.critical_issues,
			runTime: session.run_time,
			sessionName: session.session_name,
			createdBy: session.user_name,
			labels: mapCustomFieldsToValueStrings(session.custom_fields, labelsConfig)
		})) ?? [];

	let total = 0;
	if (rawResults.total?.length) {
		total = rawResults.total[0]?.run_id_count ?? 0;
	}

	let unfilteredTotal = 0;
	if (rawResults.unfiltered_total?.length) {
		unfilteredTotal = rawResults.unfiltered_total[0]?.run_id_count ?? 0;
	}

	return {
		rows,
		total,
		unfilteredTotal
	};
};
