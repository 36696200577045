import { IWfaLabel } from 'src/apps/wfa/services/WfaDashboardServiceTypes';

import { ILabelConfig } from '../../../common/types/LabelsTypes';

export const filterAvailableLabels = (
	labelConfigs: ILabelConfig[],
	savedValues?: IWfaLabel[]
): ILabelConfig[] =>
	labelConfigs
		.map((labelConfig) => {
			const savedValue = savedValues?.find((label) => label.labelKey === labelConfig.key);
			const options = labelConfig.options?.filter(
				// if deleted option is already selected, keep it
				(option) =>
					(!option.isDeleted && !labelConfig.isDeleted) || savedValue?.valueKey === option.key
			);

			return { ...labelConfig, options };
		})
		.filter((labelConfig) => {
			const savedValue = savedValues?.find((label) => label.labelKey === labelConfig.key);

			// if the deleted label is already selected, keep it with the selected option
			if (labelConfig.isDeleted && savedValue) {
				return true;
			}
			// otherwise, if it's deleted or doesn't have non-deleted options, filter it out
			if (labelConfig.isDeleted || !labelConfig.options?.length) {
				return false;
			}

			return true;
		});
