import React, { FC } from 'react';

import { useSkipLinks } from '@evinced-private/ui-common';

import { WfaSessionNotSyncedYetPopup } from 'src/apps/wfa/components/WfaSessionNotSyncedYetPopup';
import { useWfaSessionsQuery } from 'src/apps/wfa/hooks/useWfaSessionsQuery';
import { useWfaUsageDataQuery } from 'src/apps/wfa/hooks/useWfaUsageDataQuery';
import { WfaSessionsPageError } from 'src/apps/wfa/pages/error-pages/WfaSessionsPageError';
import { HorizontalDivider } from 'src/common/components/horizontal-divider/HorizontalDivider';
import { WFA_SESSIONS_TABLE_ID } from 'src/common/pages/dashboards-container/dashboard_consts';
import { useConfiguration } from 'src/common/providers/configurationProvider/ConfigurationProvider';
import { useTableState } from 'src/common/providers/dashboard/TableStateProvider';
import { ILabelConfig } from 'src/common/types/LabelsTypes';

import { WfaSessionsPageHeader } from './sections/header/WfaSessionsHeader';
import { WfaSessionsTable } from './sections/table/WfaSessionsTable';
import { WfaSessionsUsage } from './sections/usage/WfaSessionsUsage';

import './WfaSessionsPage.scss';

export const WfaSessionsPage: FC = () => {
	const { getTableStateById, onFilterChange } = useTableState();
	const { filters, tableSort, paginationInfo } = getTableStateById(WFA_SESSIONS_TABLE_ID);

	const { configuration } = useConfiguration();
	const labelsConfig = (configuration?.WFA?.PLATFORM_LABELS_LIST ?? []) as ILabelConfig[];
	const isLabelsToggleEnabled = (configuration?.WFA?.TOGGLES as Record<string, boolean>)
		?.ENABLE_PLATFORM_LABELS as boolean;

	const usageDataQuery = useWfaUsageDataQuery(filters);

	const sessionsQuery = useWfaSessionsQuery({
		page: paginationInfo.page,
		pageSize: paginationInfo.sizePerPage,
		sort: tableSort?.[0],
		filters,
		labelsConfig
	});
	useSkipLinks(!usageDataQuery.isLoading && !sessionsQuery.isLoading);

	const unfilteredTotal = sessionsQuery.data?.unfilteredTotal ?? 0;

	if (sessionsQuery?.isError || usageDataQuery?.isError) {
		return <WfaSessionsPageError />;
	}

	if (!sessionsQuery?.isLoading && !sessionsQuery?.isPreviousData && unfilteredTotal === 0) {
		return (
			<>
				<WfaSessionsPageError isNoData />;
				<WfaSessionNotSyncedYetPopup />
			</>
		);
	}

	return (
		<div className="wfa-sessions-page">
			<WfaSessionsPageHeader
				filters={filters}
				onFilterChange={onFilterChange}
				labelsConfig={labelsConfig}
				isLabelsToggleEnabled={isLabelsToggleEnabled}
			/>
			<HorizontalDivider />
			<WfaSessionsUsage
				data={usageDataQuery.data}
				isLoading={usageDataQuery.isLoading}
				selectedPeriodLabel={filters.sessionsPeriod?.[0]?.label}
			/>
			<WfaSessionsTable
				sessionsQuery={sessionsQuery}
				unfilteredTotal={unfilteredTotal}
				isLabelsToggleEnabled={isLabelsToggleEnabled}
			/>
			<WfaSessionNotSyncedYetPopup />
		</div>
	);
};
