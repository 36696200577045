import React, { FC } from 'react';

import {
	BarDatum,
	EvincedColors,
	EvSimpleBarChart,
	renderCustomTooltip
} from '@evinced-private/ui-common';

import { SeverityCountType } from 'src/apps/wfa/types/SeverityCountType';
import { generateUUID } from 'src/common/helpers/UUIDHelper';

import { EvChartTableLink } from '../ev-chart-table-link/EvChartTableLink';

import { prepareDataForSeverityChart } from './SeveritiesChartHelper';

import './BreakdownBySeveritiesChart.scss';

const { severitiesPatterns } = EvincedColors;

interface IBreakdownBySeverityChartProps {
	severitiesCount: SeverityCountType[];
	onBarItemClick: (filterOptions: BarDatum) => void;
}

export const BreakdownBySeverityChart: FC<IBreakdownBySeverityChartProps> = ({
	severitiesCount,
	onBarItemClick
}: IBreakdownBySeverityChartProps) => {
	const customSrSummaryRenderer = (barData: BarDatum[]): JSX.Element => {
		return (
			<ul aria-label="Severities Breakdown Chart Summary">
				{barData.map(({ id, severity, count }) => {
					const typeText = severity && severity !== id ? severity : '';
					const text = `${typeText}: ${count} issues`;
					return <li key={generateUUID()}>{text}</li>;
				})}
			</ul>
		);
	};

	if (severitiesCount.length === 0) {
		return null;
	}

	// we're assuming the severities count list is already sorted by severity order
	const barData = prepareDataForSeverityChart(severitiesCount);
	const severityFieldFormatter = (cell: string, row): React.ReactNode => {
		return (
			<EvChartTableLink
				value={cell}
				onClick={() => {
					onBarItemClick(row);
				}}
			/>
		);
	};

	return (
		<EvSimpleBarChart
			title="Breakdown By Issue Severity"
			data={barData}
			patterns={severitiesPatterns}
			className="breakdown-by-severity-chart"
			width="100%"
			height={400}
			pointWidth={40}
			customSrSummaryRenderer={() => customSrSummaryRenderer(barData)}
			onBarClick={(barData: Highcharts.Point) => {
				onBarItemClick({
					severity: barData.name,
					searchText: ''
				});
			}}
			customTooltip={(context: { y: number; name: string }): string => {
				const { y, name } = context;
				return renderCustomTooltip(y, name, 'Issue');
			}}
			withLegend={false}
			isFocusable={true}
			tableProps={{
				dataType: 'Severity',
				columnsPattern: [
					{
						name: 'Severity',
						field: 'label',
						formatter: severityFieldFormatter
					},
					{
						name: 'Total Issues',
						field: 'count'
					}
				]
			}}
		/>
	);
};
