import React, { useMemo } from 'react';

import { EvInfoTooltip, EvSelect, OptionType } from '@evinced-private/ui-common';

import './LabelsSelect.scss';

interface ILabelsSelect {
	labelKey: string;
	name: string;
	options: OptionType[];
	value: OptionType[];
	onChange: (selected: OptionType[]) => void;
	isSearchable?: boolean;
	isMulti?: boolean;
	placeholder?: string;
	ariaLabel?: string;
	description?: string;
}

export const LabelsSelect = ({
	labelKey,
	name,
	options,
	value,
	onChange,
	isSearchable = false,
	isMulti = false,
	placeholder = 'Select an option',
	ariaLabel = 'Select dropdown',
	description = null
}: ILabelsSelect): JSX.Element => {
	const tooltipId = `labels-select-tooltip-${labelKey}`;
	const fullOptions = useMemo(() => {
		return [
			{
				value: '',
				label: `No ${name}`
			},
			...options
		];
	}, [name, options]);

	return (
		<div className="labels-select">
			<div className="label-wrapper">
				<label htmlFor={labelKey}>{ariaLabel}</label>
				{description?.length && <EvInfoTooltip id={tooltipId}>{description}</EvInfoTooltip>}
			</div>
			<EvSelect
				id={labelKey}
				options={fullOptions}
				value={value}
				onChange={onChange}
				isSearchable={isSearchable}
				isMulti={isMulti}
				placeholder={placeholder}
				ariaLabel={ariaLabel}
			/>
		</div>
	);
};
