import React, { FC, useState } from 'react';

import classNames from 'classnames';

import {
	BUTTON_TYPES,
	EvButton,
	EvIcon,
	EvSection,
	EvSpinner,
	EvTitle
} from '@evinced-private/ui-common';

import {
	WFA_SESSION_COPY_URL_BUTTON_DAID,
	WFA_SESSION_HEADER_DAID,
	WFA_SESSION_OPEN_EDIT_SESSION_MODAL_DAID,
	WFA_SESSION_TITLE_DAID
} from 'data-automation-ids/WfaDashboardAutomationIds';
import { EditSessionPopup } from 'src/apps/wfa/components/edit-session-popup/EditSessionPopup';
import { useWfaSessionBreadcrumbs } from 'src/apps/wfa/hooks/useWfaSessionBreadcrumbs';
import { useWfaSessionDetailsQuery } from 'src/apps/wfa/hooks/useWfaSessionDetailsQuery';
import { getCurrentWFAFullUrl } from 'src/apps/wfa/services/ShareableLinksService';
import { IWfaLabel } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import { CopyToClipboard } from 'src/common/components/copy-to-clipboard/CopyToClipboard';
import EditIcon from 'src/common/components/icons/EditIcon.svg';
import LinkIcon from 'src/common/components/icons/LinkIcon.svg';
import { ReportInfo } from 'src/common/components/report-info/ReportInfo';
import { useConfiguration } from 'src/common/providers/configurationProvider/ConfigurationProvider';
import {
	WFA_SESSION_DETAILS,
	WFA_SESSIONS
} from 'src/common/providers/reactQueryProvider/QueryKeys';
import { queryClient } from 'src/common/providers/reactQueryProvider/ReactQueryProvider';
import { Logger } from 'src/common/services/Logger';
import { ILabelConfig } from 'src/common/types/LabelsTypes';

import { getWfaInfoItems } from './WfaSingleSessionHeaderHelper';

import './WfaSingleSessionHeader.scss';

interface IWfaSessionHeaderProps {
	sessionId: string;
	onSessionNotFoundCallback: () => void;
}

export const WfaSingleSessionHeader: FC<IWfaSessionHeaderProps> = ({
	sessionId,
	onSessionNotFoundCallback
}) => {
	const { configuration } = useConfiguration();
	const labelsConfig = (configuration?.WFA?.PLATFORM_LABELS_LIST ?? []) as ILabelConfig[];
	const isLabelsToggleEnabled = (configuration?.WFA?.TOGGLES as Record<string, boolean>)
		?.ENABLE_PLATFORM_LABELS as boolean;

	const sessionDetailsQuery = useWfaSessionDetailsQuery(
		sessionId,
		labelsConfig,
		isLabelsToggleEnabled
	);
	const { name, labels } = sessionDetailsQuery.data ?? {};
	const [isEditSessionModalOpen, setIsEditSessionModalOpen] = useState(false);
	const [lastUpdatedName, setLastUpdatedName] = useState<string | null>(null);
	const [lastUpdatedLabels, setLastUpdatedLabels] = useState<IWfaLabel[] | null>(null);

	const currentName = lastUpdatedName ?? name;
	const currentLabels = lastUpdatedLabels ?? labels;

	useWfaSessionBreadcrumbs(sessionId, currentName);

	if (!sessionId || sessionDetailsQuery.isError || (sessionDetailsQuery.data && !name)) {
		onSessionNotFoundCallback();
		return null;
	}

	const handleUpdate = async (newName?: string, selectedLabels?: IWfaLabel[]): Promise<void> => {
		try {
			// set the values to update the ui immediately because the update api
			// returns a 200 but actually updates in ~10 seconds so refetching immediately won't work
			if (newName) {
				setLastUpdatedName(newName);
			}
			if (selectedLabels) {
				setLastUpdatedLabels(selectedLabels);
			}
			// invalidate the queries to refetch later
			await queryClient.invalidateQueries({ queryKey: [WFA_SESSION_DETAILS, sessionId] });
			await queryClient.invalidateQueries({ queryKey: [WFA_SESSIONS] });
		} catch (e) {
			Logger.error('Error invalidating session data query after update', e);
		}
	};

	return sessionDetailsQuery.isLoading ? (
		<EvSpinner />
	) : (
		<div className="wfa-single-session-header" data-automation-id={WFA_SESSION_HEADER_DAID}>
			<div className="title-container">
				<EvTitle
					titleText={currentName}
					headingLevel={1}
					className="main-title page-heading"
					dataAutomationId={WFA_SESSION_TITLE_DAID}
				/>
				<CopyToClipboard
					renderJustIcon
					renderTooltip
					textToCopy={getCurrentWFAFullUrl(sessionId)}
					className="copy-link-button"
					iconTitle="Copy Session URL"
					icon={LinkIcon}
					dataAutomationId={WFA_SESSION_COPY_URL_BUTTON_DAID}
				/>
				<EvButton
					title="Edit Session Button"
					type={BUTTON_TYPES.ICON}
					onClick={() => setIsEditSessionModalOpen(true)}
					className="edit-session-button"
					dataAutomationId={WFA_SESSION_OPEN_EDIT_SESSION_MODAL_DAID}
				>
					<EvIcon className="edit-icon" icon={EditIcon} />
				</EvButton>
			</div>
			<EvSection
				ariaLabel={`Session ${currentName} info`}
				skipLinkId="session-info-section"
				ariaLive="polite"
				className={classNames('dashboard-section', 'web-sdk-test-info-section')}
			>
				<ReportInfo
					titleText="Session Info"
					reportInfo={getWfaInfoItems(currentLabels)}
					reportDetails={sessionDetailsQuery.data}
				/>
			</EvSection>
			<EditSessionPopup
				isOpen={isEditSessionModalOpen}
				onClose={() => setIsEditSessionModalOpen(false)}
				onSubmit={handleUpdate}
				sessionId={sessionId}
				sessionName={currentName}
				labels={currentLabels}
			/>
		</div>
	);
};
