import { isProduction } from '../../helpers/EnvironmentHelper';
import { Logger } from '../Logger';

declare global {
	interface Window {
		_hsq: [];
	}
}

export const hubspotAPIKey = process.env.HUBSPOT_API_KEY;

export function initHubspotClient(): void {
	if (!isProduction()) {
		// eslint-disable-next-line no-console
		console.log('Hubspot should not run on this env. Aborting.');
		return;
	}

	const hubspotScript = document.createElement('script');
	hubspotScript.setAttribute('type', 'text/javascript');
	hubspotScript.setAttribute('id', 'hs-script-loader');
	hubspotScript.setAttribute('src', `//js.hs-scripts.com/${hubspotAPIKey}.js`);
	hubspotScript.async = true;
	hubspotScript.defer = true;
	hubspotScript.onload = () => {
		Logger.info('hubspot service was initialized');
	};
	hubspotScript.onerror = (event, source, lineno, colno, error) => {
		Logger.error('Could not initialize hubspot service. Got error:', {
			event,
			source,
			lineno,
			colno,
			error
		});
	};
	document.head.appendChild(hubspotScript);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getHubspotClient(): any[] {
	// copy paste from hubspot code -
	// https://developers.hubspot.com/docs/api/events/tracking-code
	// eslint-disable-next-line no-multi-assign
	const client = (window._hsq = window._hsq || []);
	return client;
}

export function identifyUserByEmail(email: string): void {
	const client = getHubspotClient();
	client.push(['identify', { email }]);
}

export function trackLogin(): void {
	const client = getHubspotClient();
	client.push(['trackEvent', { id: 'login' }]);
}

export function sendPageView(url: string): void {
	const client = getHubspotClient();
	client.push(['setPath', url]);
	client.push(['trackPageView']);
}
