import { IWfaLabel } from '../WfaDashboardServiceTypes';

type EntityName = 'all' | 'issues' | 'pages';

type Condition = {
	field: string;
	value: string;
};

type Scope = {
	conditions: Condition[];
	entityName: EntityName;
};

export type Change = {
	fieldName: string;
	value: unknown;
	operation: 'set' | 'add' | 'remove';
};
type RequestPayload = {
	scope: Scope;
	changes: Change[];
};

export function buildUpdateUrl(): string {
	return `${process.env.API_ENDPOINT}/update/wfa`;
}

export const buildBasePayloadForUpdateRequest = (
	sessionId: string,
	entityName: EntityName
): RequestPayload => {
	return {
		scope: {
			conditions: [
				{
					field: 'run_id',
					value: sessionId
				}
			],
			entityName
		},
		changes: []
	};
};

const extractSingleLabelValueChanges = (
	labelKey: string,
	oldValues: string[],
	newValues: string[]
): Change[] => {
	const oldValuesSet = new Set(oldValues);
	const newValuesSet = new Set(newValues);

	const allValues = new Set([...oldValuesSet, ...newValuesSet]);

	const changes: Change[] = [];

	allValues.forEach((value) => {
		const inOld = oldValuesSet.has(value);
		const inNew = newValuesSet.has(value);

		let operation;
		if (!inOld && inNew) {
			operation = 'add';
		}
		if (inOld && !inNew) {
			operation = 'remove';
		}

		if (operation) {
			changes.push({
				fieldName: 'custom_fields',
				value: [labelKey, value],
				operation
			});
		}
	});

	return changes;
};

export const buildModificationsArrayForCustomFields = (
	oldCustomFields?: IWfaLabel[],
	newCustomFields?: IWfaLabel[]
): Change[] => {
	const allKeys = new Set([
		...(oldCustomFields?.map(({ labelKey }) => labelKey) ?? []),
		...(newCustomFields?.map(({ labelKey }) => labelKey) ?? [])
	]);

	const changes = [];
	allKeys.forEach((labelKey) => {
		const singleLabelChanges = extractSingleLabelValueChanges(
			labelKey,
			oldCustomFields
				.filter((label) => label.labelKey === labelKey)
				.map(({ valueKey }) => valueKey) ?? [],
			newCustomFields
				.filter((label) => label.labelKey === labelKey)
				.map(({ valueKey }) => valueKey) ?? []
		);

		changes.push(...singleLabelChanges);
	});

	return changes;
};
