/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useMemo, useState } from 'react';

import {
	BUTTON_TYPES,
	EvButton,
	EvIcon,
	EvInstructions,
	EvSection,
	EvTextInput,
	EvTitle,
	OptionType,
	TITLE_MODES,
	TOAST_STATUS
} from '@evinced-private/ui-common';

import { filterAvailableLabels } from 'src/apps/wfa/helpers/LabelsHelper';
import { IWfaLabel } from 'src/apps/wfa/services/WfaDashboardServiceTypes';
import BlueInfoIcon from 'src/common/components/icons/BlueInfoIcon.svg';
import { useConfiguration } from 'src/common/providers/configurationProvider/ConfigurationProvider';
import { useNotifications } from 'src/common/providers/notificationsProvider/NotificationsConsumer';
import { getFromLocalStorage, saveToLocalStorage } from 'src/common/services/api/LocalStorageApi';
import { Logger } from 'src/common/services/Logger';
import { ILabelConfig } from 'src/common/types/LabelsTypes';

import { PlatformPopup } from '../../../../common/components/platform-popup/PlatformPopup';
import { updateSession } from '../../services/wfa-session-update-service/WfaSessionUpdateService';

import {
	ARIA_LABEL_ID,
	DISMISS_MESSAGE,
	EDIT_SESSION_POPUP_SHOW_INFO_MESSAGE,
	INSTRUCTIONS
} from './consts';
import { LabelsSelect } from './LabelsSelect';

import './EditSessionPopup.scss';

interface IEditSessionPopup {
	isOpen: boolean;
	sessionName: string;
	sessionId: string;
	labels: IWfaLabel[];
	onClose?: () => void;
	onSubmit?: (name?: string, labels?: IWfaLabel[]) => Promise<unknown>;
}

const transformOptions = (options: { key: string; name: string }[]): OptionType[] => {
	return options.map(({ key, name }) => ({
		label: name,
		value: key
	}));
};

export const EditSessionPopup: FC<IEditSessionPopup> = ({
	isOpen = false,
	sessionName,
	sessionId,
	labels,
	onClose,
	onSubmit
}) => {
	const notificationsContext = useNotifications();
	const { configuration } = useConfiguration();

	const [name, setName] = useState(sessionName);
	const [selectedLabels, setSelectedLabels] = useState(labels || []);
	const [showInfoMessage, setShowInfoMessage] = useState(true);

	const isLabelsToggleEnabled = (configuration.WFA?.TOGGLES as Record<string, boolean>)
		?.ENABLE_PLATFORM_LABELS as boolean;

	const labelsList = configuration?.WFA?.PLATFORM_LABELS_LIST as ILabelConfig[];
	const availableLabelsList: ILabelConfig[] = useMemo(
		() => filterAvailableLabels(labelsList || [], labels || []),
		[labelsList, labels]
	);

	useEffect(() => {
		const showMessage: boolean = getFromLocalStorage(EDIT_SESSION_POPUP_SHOW_INFO_MESSAGE);
		setShowInfoMessage(showMessage);
	}, []);

	useEffect(() => {
		setName(sessionName);
	}, [sessionName]);

	useEffect(() => {
		setSelectedLabels(labels);
	}, [labels]);

	const dismissMessage = (): void => {
		saveToLocalStorage(EDIT_SESSION_POPUP_SHOW_INFO_MESSAGE, false);
		setShowInfoMessage(false);
	};

	const onConfirm = async (): Promise<void> => {
		try {
			await onSubmit?.(name, selectedLabels);
			await updateSession(sessionId, name, labels, selectedLabels);

			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.SUCCESS,
				announcement: 'Session updated successfully'
			});
		} catch (e) {
			Logger.error('Error updating session', e);
			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.FAIL,
				announcement: 'Failed to update session'
			});
		} finally {
			onClose();
		}
	};

	const onCancel = (): void => {
		onClose();
	};

	const handleChange = (labelConfig: ILabelConfig, newValue: OptionType | OptionType[]): void => {
		const newValuesNormalized = [].concat(newValue);

		setSelectedLabels((prev) => {
			const prevValues = [...prev];

			const changedLabelIndex = prevValues.findIndex(
				(prevValue) => prevValue.labelKey === labelConfig.key
			);

			// if No option was selected, remove the selection from the session
			if (!newValuesNormalized?.[0]?.value) {
				prevValues.splice(changedLabelIndex, 1);
				return prevValues;
			}
			const newLabel: IWfaLabel = {
				name: labelConfig.name,
				labelKey: labelConfig.key,
				value: newValuesNormalized?.[0]?.label,
				valueKey: newValuesNormalized?.[0]?.value.toString()
			};

			// if the option for an existing label was changed, replace the label entry in the session
			if (changedLabelIndex > -1) {
				prevValues.splice(changedLabelIndex, 1, newLabel);
				return prevValues;
			}

			// if there was no selected option for the label before, just add it
			return [...prevValues, newLabel];
		});
	};

	const buttons = [
		{
			onClick: onCancel,
			title: 'Cancel',
			type: BUTTON_TYPES.SECONDARY,
			children: 'Cancel'
		},
		{
			onClick: onConfirm,
			title: 'Save',
			type: BUTTON_TYPES.PRIMARY,
			children: 'Save'
		}
	];

	const renderEditSessionForm = (): JSX.Element => (
		<div className="edit-session-form">
			{showInfoMessage && (
				<div className="info-message-container">
					<div className="info-message">
						<EvIcon small icon={BlueInfoIcon} />
						<div>{DISMISS_MESSAGE}</div>
					</div>
					<EvButton type={BUTTON_TYPES.SECONDARY} className="dismiss-btn" onClick={dismissMessage}>
						Dismiss
					</EvButton>
				</div>
			)}
			<label id={ARIA_LABEL_ID}>Session name</label>
			<EvTextInput
				ariaLabelledBy={ARIA_LABEL_ID}
				value={name}
				className="field rename-session-input"
				onChange={setName}
				maxLength="255"
				isRequired
			/>

			{isLabelsToggleEnabled && (
				<EvSection>
					<EvTitle
						className="platform-labels-title"
						mode={TITLE_MODES.NORMAL}
						headingLevel={3}
						titleText="Platform labels"
						id="platform-labels"
					/>
					<EvInstructions text={INSTRUCTIONS} />
					{availableLabelsList.map((item) => {
						const placeholder = `Select a ${item.name}`;
						const selectedLabel = selectedLabels?.find((label) => label.labelKey === item.key);
						let value: OptionType[];
						if (selectedLabel) {
							value = [{ label: selectedLabel?.value, value: selectedLabel?.valueKey }];
						} else {
							value = [];
						}

						return (
							<LabelsSelect
								key={item.key}
								labelKey={item.key}
								name={item.name}
								options={transformOptions(item.options)}
								value={value}
								onChange={(selected) => handleChange(item, selected)}
								isSearchable
								placeholder={placeholder}
								ariaLabel={item.name}
								description={item.description}
							/>
						);
					})}
				</EvSection>
			)}
		</div>
	);
	return (
		<PlatformPopup
			popupWidth={620}
			className="edit-session-popup"
			titleMode={TITLE_MODES.NORMAL}
			title="Edit Session"
			isOpen={isOpen}
			onClose={onClose}
			buttons={buttons}
			isCentered
			isControlled
			isBgTransparent
		>
			{renderEditSessionForm()}
		</PlatformPopup>
	);
};
